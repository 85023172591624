<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import { useFormField } from './useFormField';
import { cn } from '@laam/lib/utils';

const props = defineProps<{
	class?: HTMLAttributes['class'];
}>();

const { formDescriptionId } = useFormField();
</script>

<template>
	<p
		:id="formDescriptionId"
		:class="cn('text-sm text-neutral-500 dark:text-neutral-400', props.class)"
	>
		<slot />
	</p>
</template>
